// Date Picker
.react-datepicker {
  &__input-container {
    width: 100%;

    input {
      width: 100%;
      padding: 12px 16px;
      border: 1px solid #c8cbce;
      border-radius: 4px;
      &:focus-visible {
        border: 1px solid #c8cbce;
      }
    }
  }

  &__tab-loop {
    position: absolute;
    width: 100%;
  }
}

.react-datepicker-popper {
  .react-datepicker {
    width: 100%;

    .react-datepicker__month-container {
      width: 100%;

      .react-datepicker {
        &__day-names {
          display: flex;
          justify-content: space-around;
        }
        &__week {
          display: flex;
          justify-content: space-around;
        }
        &__day--keyboard-selected {
          border-radius: 0;
          background-color: #ffb100;
          color: #212529;
        }
        &__day--selecting-range-start {
          border-radius: 0;
          background-color: #ffb100;
          color: #212529;
        }
        &__day--selected {
          border-radius: 0;
          background-color: #ffb100;
          color: #212529;
        }
        &__day--in-range {
          border-radius: 0;
          background-color: #ffb100;
          color: #212529;
        }
        &__day--in-selecting-range {
          border-radius: 0;
          background-color: #ffe7b0;
          color: #212529;
        }
      }
    }
  }
}

// Time Picker
.react-time-input-picker-wrapper {
  width: 100%;
  .react-time-input-picker {
    display: flex;
    justify-content: center;
    align-items: center;
    .inputWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50px;
      height: 50px;
      border: 1px solid #c8cbce;
      border-radius: 4px;
      margin: 0 8px;

      input {
        text-align: center;

        &:focus {
          outline: none;
        }
      }

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        appearance: textfield;
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }
}
