@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.wallet-adapter-dropdown {
  width: 100%;
}

.wallet-adapter-button {
  background-color: #383e46 !important;
}

.wrapper-video-webcam {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px dashed #c8cbce;
  video {
    padding: 12px;
    height: 281px !important;
  }
}

.loading-bullet {
  color: #ffb100 !important;
}
